@use "sass:meta" as ---uesajyerrrs;.recommendations {
  margin-bottom: 1rem;
  position: relative;
  border: 1px solid $medium-gray;
  box-sizing: border-box;

  &__img-wrap {
    @include obj-thumb(166px, 238px);
  }

  &:hover &__desc {
    opacity: 1;
  }

  &__desc {
    opacity: 0;
    transition: all .4s;
    background-color: rgb(44 41 44 / 33%);
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  &__img-ref {
    position: relative;
    display: block;
    text-align: center;
    height: 59%;
  }

  &__zoom {
    position: absolute;
    left: calc(50% - 22.5px);
    top: calc(80% - 22.5px);
    background-image: url('svg/recommendations-zoom.svg');
    background-repeat: no-repeat;
    display: inline-block;
    width: 45px;
    height: 45px;
  }

  &__c-ref {
    color: $white !important;
  }

  &__c-name {
    height: 97px;
    display: block;
    text-align: center;
    padding-top: 20px;
  }
}

;@include ---uesajyerrrs.load-css("sass-embedded-legacy-load-done:3850");