@use "sass:meta" as ---uesajyerrrs;.slider-3 {
  margin: 0;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;

  li {
    list-style: none;
    display: inline-block;
    width: 83px;

    @include breakpoint(medium only) {
      margin-right: 16px;

      &:nth-child(4n) {
        margin-right: 0;
      }
    }

    @include breakpoint(large) {
      margin-right: 5px;

      &:nth-child(5n) {
        margin-right: 0;
      }
    }

    img {
      vertical-align: top;
      object-fit: cover;
      aspect-ratio: 4/3;
    }
  }

  .slick-slide {
    img {
      max-width: 100%;
      vertical-align: top;
    }
  }
}

;@include ---uesajyerrrs.load-css("sass-embedded-legacy-load-done:3861");