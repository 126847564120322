@use "sass:meta" as ---qyzyywc1vw;@use 'sass:math';

html {
  height: 100%;
}

body {
  background-color: #f2f2f2;

  @include breakpoint(small only) {
    height: 100%;
    position: relative;
    background-color: #f5f5ea;
  }
}

address {
  font-style: normal;
}

iframe {
  border: none;
}

pre {
  font-family: $font-family-monospace;
  font-weight: $global-weight-bold;
  color: $light-black;
}

.off-canvas-wrapper_overlay {
  height: 100%;
}

.off-canvas-content {
  padding-top: 0;
}

.js-off-canvas-overlay.is-visible {
  z-index: 1020;
}

.wrap {
  position: relative;
  margin: 0 auto;
  z-index: 3; // для брендирования !!!

  @include breakpoint(large) {
    width: rem-calc($site-width-large);
  }

  @include breakpoint(xlarge) {
    width: rem-calc($site-width-xlarge);
  }

  &-shadow {
    width: 100%;
    background: $white;
    padding: 0;
    position: relative;
    overflow: hidden auto;
    margin: 0 auto;

    @include breakpoint(large) {
      border: 1px solid #d9d9d9;
      box-sizing: border-box;
      max-width: rem-calc($site-width-large + -zf-get-bp-val($grid-column-gutter, large) * 2);
      border-top: none;
      border-bottom: none;
      box-shadow: 0 0 4px rgb(0 0 0 / 30%);
    }

    @include breakpoint(xlarge) {
      max-width: $global-width + rem-calc(-zf-get-bp-val($grid-column-gutter, xlarge) * 2);
    }
  }
}

.column-with-sidebar {
  @include breakpoint(large only) {
    @include flex-grid-column(
      math.div($site-width-large - -zf-get-bp-val($grid-column-gutter, large) - 240, $site-width-large)
    );
  }

  @include breakpoint(xlarge) {
    @include flex-grid-column(
      math.div($site-width-xlarge - -zf-get-bp-val($grid-column-gutter, xlarge) - 240, $site-width-xlarge)
    );
  }
}

.column-sidebar {
  @include show-for(large);
  @include flex-grid-column(
    math.div(240 + -zf-get-bp-val($grid-column-gutter, large), $site-width-large)
  );

  @include breakpoint(xlarge) {
    @include flex-grid-column(
      math.div(240 + -zf-get-bp-val($grid-column-gutter, xlarge), $site-width-xlarge)
    );
  }

  margin-bottom: $global-margin;
  padding-bottom: $global-padding;

  @media print {
    display: none;
  }

  #bs22,
  #bs51,
  #bs52,
  #bs53 {
    @include grid-column-margin;
  }
}

.cell {
  &.cell-with-left-menu {
    $size: auto;

    @include xy-cell-base($size);
    @include xy-cell-size($size);

    order: 1;
  }

  &.cell-left-menu {
    order: 0;

    @include breakpoint(medium) {
      width: 160px;
    }

    @include breakpoint(large) {
      width: 200px;
    }
  }

  &.cell-right-menu {
    order: 2;

    @include breakpoint(medium) {
      width: 200px;
    }

    @include breakpoint(large) {
      width: 240px;
    }
  }
}

.media-object-section {
  @include breakpoint(medium down) {
    &:first-child {
      padding-right: .5rem;
    }
  }
}

.media-object.stack-for-small {
  @include breakpoint(small only) {
    .media-object-section {
      img {
        display: block;
        width: auto;
        max-width: 100%;
        margin: 10px auto 0;
      }
    }
  }
}

.policyAgree {
  display: none;
  position: fixed;
  bottom: 0;
  background-color: $white;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 1002;
  padding: 1rem;
  gap: 1rem;
  font-size: rem-calc(14);
  box-shadow: 0 2px 6px 0 rgb(152 149 149 / 86%);

  @include breakpoint(medium only) {
    gap: 4rem;
  }

  @include breakpoint(large) {
    justify-content: center;
    align-items: center;
    gap: 4rem;
  }

    &__text {
      color: $light-black;

      @include breakpoint(medium down) {
        font-size: rem-calc(10);
      }

      a {
        text-decoration: underline;
        color: map-get($foundation-palette, san-marino);
      }
    }

    &__button {
      align-items: center;
      justify-content: center;
      background-color: map-get($foundation-palette, san-marino);
      border-radius: $global-radius;
      display: inherit;
      padding: 0 1rem;
      width: 115px;
      color: $white;
      cursor: pointer;

      @include breakpoint(small) {
        align-self: center;
        padding: .5rem 1rem;
      }
    }
}

;@include ---qyzyywc1vw.load-css("sass-embedded-legacy-load-done:3825");