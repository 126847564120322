@use "sass:meta" as ---ry84wxtmkt8;.help-content {
  h2 {
    margin-top: $global-margin;
    margin-bottom: $global-margin;
  }

  .callout {
    margin-top: $global-margin;
  }

  .table {
    &-wrapper {
      overflow: scroll;
      margin: 1.5rem 0;

      table {
        font-size: rem-calc(14);
        border-collapse: collapse;

        td,
        th {
          border: 1px solid $medium-gray;
        }

        td:not(:first-child),
        th:not(:first-child) {
          text-align: center;
        }

        td {
          min-width: 120px;

          &:first-child {
            background-color: #f9f9f9;
            border: 1px solid $medium-gray;
          }
        }

        tr {
          &:hover {
            background-color: #f9f9f9;
          }
        }
      }

      &.summary {
        tr:last-child {
          font-weight: $global-weight-bold;
        }

        th {
          padding: 12px;
        }

        td {
          padding: 12px;
        }
      }
    }

    &-promo {
      textarea {
        cursor: copy;

        @include breakpoint(small only) {
          margin-top: 2rem;
        }
      }
    }
  }

  table {
    width: 100%;
    border: 1px solid $medium-gray;
  }

  a.button {
    background-color: map-get($foundation-palette, san-marino);
    color: $white;

    &:hover {
      background-color: $white;
      color: map-get($foundation-palette, san-marino);
      border: 1px solid map-get($foundation-palette, san-marino);
    }
  }

  .footnote {
    margin-top: $global-margin;
    font-size: rem-calc(13);
  }

  .form {
    display: flex;
  }
}

;@include ---ry84wxtmkt8.load-css("sass-embedded-legacy-load-done:3890");