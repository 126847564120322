@use "sass:meta" as ---ry84wxtmkt8;@use 'sass:math';

.picture_box {
  @include clearfix;

  text-align: center;
  margin: 0 0 $global-margin $global-margin;
  width: 375px;
  float: right;

  @include breakpoint(small only) {
    margin: 0 0 $global-margin;
    width: 100%;
    float: none;
  }

  @include breakpoint(medium only) {
    width: 40%;
  }

  @include breakpoint(large only) {
    width: 300px;
  }

  .picture_big {
    margin: 0 math.div($global-margin, 4) math.div($global-margin, 4);
    display: block;
    max-width: 100%;

    @include breakpoint(small only) {
      margin-left: 0;
      margin-right: 0;
    }

    img {
      max-width: 100%;
      min-width: 100%;
    }
  }

  .picture_thumb {
    margin: math.div($global-margin, 4);
    max-width: 6rem;
    max-height: 4rem;
    display: inline-block;
    overflow: hidden;

    img {
      max-width: 100%;
      min-width: 6rem;
    }
  }
}

;@include ---ry84wxtmkt8.load-css("sass-embedded-legacy-load-done:3849");