@use "sass:meta" as ---uesajyerrrs;.reveal {
  &-group {
    height: 350px;

    &__input {
      @include breakpoint(large down) {
        margin-top: 10px;
      }

      .chzn-container {
        width: 100% !important;
        margin: 0;
      }

      .chzn-single {
        height: 34px;

        @include breakpoint(small only) {
          height: 38px;
        }
      }
    }
  }
}

;@include ---uesajyerrrs.load-css("sass-embedded-legacy-load-done:3852");