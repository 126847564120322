@use "sass:meta" as ---uesajyerrrs;&.company-week {
  padding: 50px 15px 15px;
  position: relative;
  border: 1px solid $alice-blue;

  &::before {
    content: 'Компания недели';
    display: inline;
    background-color: map-get($foundation-palette, warning);
    font-size: rem-calc(14);
    padding: 4px 15px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 0 $global-radius;
    color: $black;
    font-weight: $global-weight-bolder;
  }

  .logo {
    display: flex;
    align-items: center;
    margin: 0 0 15px;
    border-bottom: 1px solid $alice-blue;
    padding-bottom: 15px;

    .img {
      width: 70px;
      line-height: 0;
      flex-shrink: 0;
    }
  }

  .name {
    font-weight: $global-weight-bold;
    font-size: rem-calc(13);
    margin-left: 12px;
    -webkit-line-clamp: 2;
  }

  .desc {
    font-size: rem-calc(13);
    text-align: left;
    -webkit-line-clamp: 6;
  }

  .more {
    a {
      display: flex;
      background-color: map-get($foundation-palette, san-marino);
      color: $white;
      justify-content: center;
      padding: 7px;
      margin-top: 15px;
      border-radius: $global-radius;
      font-size: rem-calc(14);
    }
  }
}

;@include ---uesajyerrrs.load-css("sass-embedded-legacy-load-done:3907");