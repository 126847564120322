@use "sass:meta" as ---qyzyywc1vw;@use 'sass:math';

.content-future {
  &__header {
    margin-bottom: 1.625rem;

    .rubrica {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__title {
        color: #8E8E8E;
        text-transform: uppercase;
        font-size: rem-calc(14);
      }
    }

    .favorites {
      .icon-favorites {
        width: 20px;
        height: 21px;
      }
    }

    h1 {
      @include xy-gutters($gutter-position: bottom);

      font-size: rem-calc(30);
      font-weight: $global-weight-bold;

      @include breakpoint(small only) {
        font-size: rem-calc(26);
      }
    }

    .infoline {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: rem-calc(14);
      color: $dark-gray;

      @include breakpoint(medium) {
        gap: $global-margin;
      }

      .wrapper {
        @include breakpoint(small) {
          order: 0;
        }

        @include breakpoint(medium) {
          display: flex;
          gap: $global-margin;
        }

        .company,
        .user {
          font-weight: $global-weight-bold;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          display: -webkit-box; /* stylelint-disable-line */
          overflow: hidden;
        }

        .pub-date {
          font-style: italic;

          @include breakpoint(medium) {
            flex-shrink: 0;
          }
        }
      }

      a {
        @include breakpoint(small) {
          order: 1;
        }
      }

      .comment {
        background-color: #edf2f5;
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 2px 6px;
        border-radius: $global-radius;
        color: #000;

        @include breakpoint(small only) {
          width: fit-content;
        }

        &::before {
          content: '';
          display: block;
          background-image: url('./svg/icon-comment.svg');
          background-repeat: no-repeat;
          background-size: 18px 15px;
          width: 18px;
          height: 15px;
        }
      }

      .for-staff {
        display: flex;
        gap: $global-margin;

        a {
          &:hover {
            text-decoration: underline;
          }

          &.change {
            color: map-get($foundation-palette, alert);
          }

          &.analytics {
            color: map-get($foundation-palette, warning);
          }
        }
      }
    }
  }

  &__footer {
    background-color: $alice-blue;
    font-size: rem-calc(13);
    padding: .4rem .8rem;
    margin-bottom: $global-margin;

    [data-complaint] {
      color: red;

      &:hover {
        text-decoration: underline;
      }
    }

    .icons-action {
      display: flex;
      gap: 1rem;

      .icon-favorites {
        width: 13px;
        height: 20px;
      }
    }
  }

  .grid-images, .slider-article {
    .caption {
      font-size: rem-calc(13);
      color: $dark-gray;
      font-style: italic;
      text-align: center;
      margin-top: .5rem;
    }
  }

  .grid-images {
    margin-bottom: 1rem;

    @include breakpoint(small only) {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }

    .caption {
      text-align: center;
      margin-top: .5rem;
    }
  }

  .slider-article {
    margin-bottom: $global-margin;
    width: math.div(10, 12) * 100%;

    @include breakpoint(small only) {
      width: math.div(11, 12) * 100%;
    }
  }

  .image-block {
    margin: $global-margin auto;
    width: math.div(10, 12) * 100%;

    &.stretched {
      width: 100%;

      img {
        max-height: max-content;
      }
    }

    &.with-background {
      background-color: $alice-blue;
      padding: 2rem;
    }

    &.with-border {
      img {
        border: 1px solid map_get($foundation-palette, san-marino);
      }
    }
  }
}

;@include ---qyzyywc1vw.load-css("sass-embedded-legacy-load-done:3826");