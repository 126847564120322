@use "sass:meta" as ---qyzyywc1vw;.debug-enabled {
  iframe.banner {
    background-color: $light-gray;
  }

  #id-C-A-4439-7,
  #id-C-A-4439-8,
  #id-C-A-4439-15,
  #yandex_rtb_R-A-4439-17,
  #yandex_rtb_R-A-4439-18 {
    height: 200px;
    border: solid 1px $dark-gray;
    background-color: $light-gray;
    display: block;

    &::before {
      display: block;
      content: 'Яндекс.Рекламная сеть - Рекомендательный виджет';
      text-align: center;
      line-height: 200px;
    }
  }

  #id-C-A-4439-15 {
    height: 1150px;
    width: 100%;
    padding: 5rem 1rem;
  }

  #yandex_rtb_R-A-4439-17 {
    height: 600px;
    width: 100%;
    padding: 5rem 1rem;
  }

  #yandex_rtb_R-A-4439-18 {
    height: 200px;
    width: 100%;
    padding: 5rem 1rem;
  }

  #id-C-A-4439-7::before {
    content: 'Рекомендательный виджет - Объявления (ID блока: C-A-4439-7)';
  }

  #id-C-A-4439-8::before {
    content: 'Рекомендательный виджет - Маркет (ID блока: C-A-4439-8)';
  }

  #id-C-A-4439-15::before {
    content: 'Рекомендательный виджет Sidebar (ID блока: C-A-4439-15)';
    line-height: 2rem;
  }

  #yandex_rtb_R-A-4439-17::before {
    content: 'RTB баннер Sidebar (ID блока: yandex_rtb_R-A-4439-17)';
    line-height: 2rem;
  }

  #yandex_rtb_R-A-4439-18::before {
    content: 'RTB баннер Sidebar (ID блока: yandex_rtb_R-A-4439-18)';
    line-height: 2rem;
  }
}

;@include ---qyzyywc1vw.load-css("sass-embedded-legacy-load-done:3828");