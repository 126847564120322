@use "sass:meta" as ---qyzyywc1vw;@use 'sass:math';

.m-nav {
  align-items: center;
  display: flex;
  background-color: $white;
  border-bottom: 1px solid $medium-gray;
  left: 0;
  line-height: 0;
  margin-bottom: 0;
  padding: 10px;
  position: relative;
  right: 0;
  transform: translateY(0);
  transition: transform .4s ease-out;
  width: 100%;
  z-index: 1002;

  &.mobile-hidden {
    transform: translateY(-100%);
  }

  &__wrap {
    @include hide-for(large);

    background-color: #f5f5ea;
    margin: 0;
    padding-bottom: math.div($global-padding, 2);
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1002;
  }

  &__hamburger {
    button {
      line-height: 0;
    }

    &__icon {
      @include hamburger($color: $light-black, $color-hover: $light-black, $height: 20px, $weight: 3px);
    }
  }

  &__logo {
    margin-left: 10px;
    width: 100%;

    a {
      line-height: 0;
      vertical-align: top;

      svg {
        @include breakpoint(small only) {
          width: 80%;
        }
      }
    }
  }

  &__metz {
    flex-shrink: 0;


    &-button {
      background: #2e3191;
      background: linear-gradient(90deg, #2e3191 0%, #015098 100%);
      height: 30px;
      margin-right: 1rem;
      display: flex;
      align-items: center;
      padding: 4px 8px;
      border-radius: $global-radius;
      gap: .3rem;

      &::before {
        content: '';
        display: block;
        color: $white;
        background-image: url('./svg/logo-metz-v2.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 72px;
        height: 22px;
      }

      span {
        color: $white;
        font-size: rem-calc(12);
        line-height: 1.4;
      }
    }
  }

  &__search {
    button {
      line-height: 0;
    }

    &__icon {
      background-repeat: no-repeat;
      background-image: url('svg/m-nav-search-icon-inline.svg');
      display: inline-block;
      margin-right: 10px;
      height: 30px;
      width: 30px;
    }
  }
}

;@include ---qyzyywc1vw.load-css("sass-embedded-legacy-load-done:3838");