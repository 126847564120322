@use "sass:meta" as ---qyzyywc1vw;.search-list {
  margin-bottom: $global-margin;

  &__title {
    margin: 0 0 .25rem;

    em {
      font-style: normal;
      font-weight: $global-weight-bolder;
    }

    .icon {
      position: relative;
      top: -5px;
    }

    .price {
      color: $alert-color;

      &::before {
        color: $black;
        content: '–';
        margin-left: .25rem;
        margin-right: .25rem;
      }
    }
  }

  &__info {
    font-size: $small-font-size;
    color: $dark-gray;

    a {
      color: #390;
    }
  }

  &__tizer {
    margin-bottom: .25rem;
    max-height: rem-calc($global-font-size * $list-lineheight * 3);
    overflow: hidden;
    text-overflow: ellipsis;

    em {
      font-style: normal;
      font-weight: $global-weight-bolder;
    }
  }

  &__more {
    font-size: rem-calc(14);
    list-style: none;
    margin: 0;

    li {
      &::before {
        content: '—';
        padding-right: 2px;
      }

      .price {
        color: $alert-color;

        &::before {
          content: '–';
          margin-left: .25rem;
          margin-right: .25rem;
        }
      }
    }
  }
}

;@include ---qyzyywc1vw.load-css("sass-embedded-legacy-load-done:3854");