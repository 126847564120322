@use "sass:meta" as ---ry84wxtmkt8;.errorlist {
  display: flex;
  flex-flow: column;
  margin: -$global-margin 0 $global-margin;

  li {
    color: $alert-color;
    display: block;
    list-style-type: none;
    padding: $input-padding;
  }
}

;@include ---ry84wxtmkt8.load-css("sass-embedded-legacy-load-done:3872");