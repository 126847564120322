div[data-favorites] {
  cursor: pointer;

  h1 & {
    display: inline-block;

    .icon-favorites {
      width: 13px;
      height: 20px;
    }
  }
}

.icon-favorites {
  display: inline-block;
  width: 10px;
  height: 17px;
  background-repeat: no-repeat;
  background-image: url('svg/bookmark-inline.svg');
  vertical-align: middle;

  &-added,
  .is-exists & {
    background-image: url('svg/bookmark-added-inline.svg');
  }

  &-delete {
    width: 9px;
    height: 9px;
    background-image: url('svg/icon-bookmark-delete.svg');
  }

  &-deleted {
    width: 9px;
    height: 9px;
    background-image: url('svg/icon-bookmark-deleted.svg');
  }
}

.text-favorites {
  margin-left: .5rem;
}

.icon-like {
  display: inline-block;
  width: 21px;
  height: 20px;
  background-repeat: no-repeat;
  background-image: url('svg/heart-inline.svg');
  vertical-align: middle;

  &.apply {
    background-image: url('svg/heart-apply-inline.svg');
  }
}
