@use "sass:meta" as ---qyzyywc1vw;.news-template {
  &__content {
    margin-top: rem-calc(26);

    .image-container {
      display: flex;
      justify-content: center;
      margin-bottom: 21px;

      figure {
        max-width: 560px;
        margin-bottom: 0;
      }

      a {
        width: 90%;
        line-height: 0;

        img {
          object-fit: contain;
        }
      }

      &__caption {
        margin-top: 12px;
        font-size: rem-calc(13);
        line-height: initial;
        color: $dark-gray;
        text-align: center;
      }
    }

    [itemprop='articleBody'] {
      .responsive-embed.widescreen {
        max-width: 560px;
        margin: 2rem auto;
      }

      ul, ol {
        margin-left: 2.5rem;
      }

      h2, h3, h4, h5, h6 {
        font-weight: $global-weight-bold;
        margin-top: 26px;

        @include breakpoint(small only) {
          border: none;
          padding: inherit;
          background-color: inherit;
        }
      }

      h2 {
        font-size: rem-calc(24);
      }

      h3 {
        font-size: rem-calc(22);
      }

      h4 {
        font-size: rem-calc(20);
      }

      h5 {
        font-size: rem-calc(18);
      }

      h6 {
        font-size: rem-calc(16);
      }

      .info {
        font-size: rem-calc(15);
        line-height: 1.6;
        color: #4E4E4E;
        background-color: unset;
        padding: unset;
        margin: 1.5rem 0 1.5rem 3rem;

        &::before {
          content: '';
          width: 30px;
          height: 45px;
          background-image: url('./svg/info.svg');
          background-repeat: no-repeat;
          background-size: 14px;
          background-position: center;
          margin-right: 1rem;
          background-color: #EDF2F5;
          align-self: flex-start;
          flex-shrink: 0;
          position: absolute;
          left: 1rem;
        }
      }

      .tizer {
        line-height: inherit;
      }

      blockquote {
        padding: .25rem 0 .25rem 1.25rem;
      }

      figure {
        max-width: 560px;
        margin-left: auto;
        margin-right: auto;
      }
    }


    .swiper {
      max-width: 560px;
      margin-bottom: 2rem;

      @include breakpoint(small) {
        height: 354px;
      }

      @include breakpoint(medium) {
        height: 464px;
      }

      @include breakpoint(large) {
        height: 484px;
      }

      &-wrapper {
        align-items: center;
      }

      &-slide {
        display: flex;
        justify-content: center;
        align-items: flex-start;

        iframe {
          max-height: 440px;
          width: 100%;
          height: auto;
        }

        img {
          display: block;
          width: 100%;
          height: 100%;
          max-height: 440px;
          object-fit: contain;
        }

        .caption {
          text-align: end;
          margin-left: 4rem;
          font-size: rem-calc(13);
          color: $dark-gray;
          position: absolute;
          bottom: 0;
          right: 0;
          display: -webkit-box; /* stylelint-disable-line */
          overflow: hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          @include breakpoint(medium down) {
            -webkit-line-clamp: 3;
          }
        }
      }

      &-pagination {
        color: $dark-gray;
        display: flex;
        background-color: white;
        width: 60px;
        padding-top: 20px;
        bottom: 0;

        &-current {
          margin-right: 4px;
          font-weight: $global-weight-bold;
        }

        &-total {
          margin-left: 4px;
        }
      }

      &-button-prev, &-button-next {
        width: auto;
        margin-top: calc(-20px - var(--swiper-navigation-size) / 2);

        &::after {
          background-color: $alice-blue;
          opacity: .6;
          padding: 20px 6px;
          font-size: rem-calc(30);
          color: $dark-gray;
        }
      }

      &-button-prev {
        left: 0;

        &::after {
          border-radius: 0 $global-radius $global-radius 0;
        }
      }

      &-button-next {
        right: 0;

        &::after {
          border-radius: $global-radius 0 0 $global-radius;
        }
      }
    }
  }
}

.pswp  {
    img {
      object-fit: scale-down;
    }
}

;@include ---qyzyywc1vw.load-css("sass-embedded-legacy-load-done:3900");