@use "sass:meta" as ---qyzyywc1vw;.related_links {
  @include breakpoint(medium) {
    li {
      list-style-image: url('svg/related-links-inline.svg');
      line-height: 2rem;
    }
  }

  ul {
    @include small-l;
  }
}

;@include ---qyzyywc1vw.load-css("sass-embedded-legacy-load-done:3851");