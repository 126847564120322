@use "sass:meta" as ---ry84wxtmkt8;.company-follow-tizer {
  @include grid-column-margin;

  padding: $global-padding;
  background-color: $alice-blue;

  @media print {
    display: none !important;
  }

  .media-object {
    margin-bottom: 0;

    img {
      max-height: 75px;
    }

    svg {
      max-width: 150px;
      height: 75px;
    }
  }

  .button {
    margin-bottom: 0;
  }

  &__desc {
    @include breakpoint(small only) {
      text-align: center;
    }

    @include breakpoint(medium) {
      margin-left: $global-margin;
    }

    span {
      font-size: rem-calc(13);
    }
  }
}

;@include ---ry84wxtmkt8.load-css("sass-embedded-legacy-load-done:3881");