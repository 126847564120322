@use "sass:meta" as ---uesajyerrrs;@use 'sass:math';

.leftmenu {
  &__logo {
    margin-bottom: $global-margin;
    padding: $global-padding;

    svg {
      width: 100%;
    }

    @include breakpoint(medium) {
      border: 1px solid get-color(grayish-blue);

      svg {
        aspect-ratio: 1;
      }
    }
  }

  &__thumb {
    margin-bottom: $global-margin;
    padding: $global-padding;
    text-align: center;

    @include breakpoint(medium) {
      align-items: center;
      aspect-ratio: 1;
      background-color: $white;
      border-radius: $global-radius;
      border: 1px solid get-color(grayish-blue);
      display: flex;
    }
  }

  &__h {
    @include show-for(medium);

    font-size: rem-calc(13);
    font-weight: $global-weight-bold;
    margin-bottom: math.div($global-margin, 4);
  }

  &__h3 {
    font-weight: $global-weight-bold;
    margin-bottom: math.div($global-margin, 4);
  }

  &__h4 {
    @include show-for(medium);

    border-bottom: 1px solid get-color(grayish-blue);
    color: $light-black;
    font-weight: $global-weight-bolder;
    margin-bottom: math.div($global-margin, 2);
  }

  &__list {
    font-size: rem-calc(15);
    list-style: none;
    margin: 0;

    @include breakpoint(small only) {
      > li:not(:last-child) {
        border-bottom: 1px solid #cfcfcf;
      }

      > li:first-child {
        padding-top: 20px;
      }

      > li {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 8px 10px;
      }

      > li.selected {
        color: #2e3191;
        font-weight: $global-weight-bold;
      }

      > li > span {
        color: #606060;
        font-size: 12px;
        margin-left: 25px;
      }
    }

    @include breakpoint(medium) {
      margin-bottom: 1rem;

      li {
        margin: 0 -4px;
        padding: 3px 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          color: $small-font-color;
          font-size: $small-font-size;
          margin-left: .7rem;
        }

        a {
          color: $light-black;
        }

        &:hover {
          background-color: scale_color(get-color(grayish-blue), $lightness: 85%);
        }

        &.selected {
          background-color: #cfe2e9;
          border-radius: $global-radius;
          color: $black;
        }
      }
    }
  }

  &__submenu {
    @include small-l;

    font-size: rem-calc(13);
    margin: 0 0 $global-margin;

    li {
      line-height: rem-calc(24);
      list-style: none;
      padding-right: 20px;
      position: relative;

      a {
        display: inline-block;
      }

      &.selected {
        @include breakpoint(medium) {
          margin-left: -4px;
          margin-right: -4px;
          padding-left: 4px;
          padding-right: 4px;
          background-color: $primary-color;
          color: $white;
          border-radius: $global-radius;

          a,
          a:visited {
            color: $white;
          }
        }

        .badge {
          background-color: $white;
          color: map_get($foundation-palette, alert) !important;
        }
      }

      svg {
        vertical-align: middle;
      }

      .badge {
        background-color: map_get($foundation-palette, alert);
        border-radius: 8px;
        color: $white;
        font-size: rem-calc(12);
        font-weight: $global-weight-bold;
        line-height: 16px;
        margin-top: 4px;
        padding: 0 5px;
        text-decoration: none;
      }

      a.badge {
        color: $white;
      }

      &.native-ads {
        > a {
          display: flex;
          align-items: center;
        }

        ::after {
          display: inline-block;
          position: absolute;
          content: '';
          width: 16px;
          height: 16px;
          right: 0;
          background-image: url('svg/icon-circle-tg-native.svg');
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: center;
        }
      }

      @include breakpoint(small only) {
        span {
          display: none;
        }
      }

      @include breakpoint(medium) {
        span {
          line-height: rem-calc(19);
          font-size: $small-font-size;
          color: $small-font-color;
          position: absolute;
          right: 4px;
          top: 0;
        }
      }
    }
  }

  &__banner {
    margin-bottom: $global-margin;
  }
}

;@include ---uesajyerrrs.load-css("sass-embedded-legacy-load-done:3836");