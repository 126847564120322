@use "sass:meta" as ---ry84wxtmkt8;.help-menu {
  @include breakpoint(medium down) {
    margin: 1rem 0 2rem;
  }

  @include breakpoint(large) {
    padding-left: 0;
  }

  &_wrapper {
    border: 1px solid $medium-gray;
    border-radius: $global-radius;
    padding: $global-padding;
    background-color: $alice-blue;

    .title {
      font-weight: $global-weight-bold;
      text-transform: uppercase;
    }

    .sections {
      margin-top: $global-margin;

      .is-accordion-submenu {
        &.is-active {
          margin: .5rem 0 .5rem 1rem;
        }

        &-parent {
          &:not(.has-submenu-toggle) > a::after {
            border-color: $medium-gray transparent transparent;
          }
        }
      }

      li.is-submenu-item.is-accordion-submenu-item {
        font-size: rem-calc(14);
      }

      a {
        color: map-get($foundation-palette, secondary);
        padding: .5rem 0;

        &:hover {
          color: $service;
        }

        &::after {
          border-color: $light-black;
        }

        &.is-active {
          color: map_get($foundation-palette, secondary);
          font-weight: $global-weight-bold;
          text-decoration-line: underline;
        }
      }
    }

    .sub-section {
      margin-left: $global-margin;

      @include breakpoint(medium down) {
        margin: 0;
        flex-direction: column;
      }
    }
  }
}

;@include ---ry84wxtmkt8.load-css("sass-embedded-legacy-load-done:3891");