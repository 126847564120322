@use "sass:meta" as ---ry84wxtmkt8;.rubricator-2 {
  dt,
  dd {
    position: relative;
    margin: 0;

    a {
      display: block;
    }

    span {
      position: absolute;
      right: 0;

      @include breakpoint(small only) {
        display: none;
      }
    }
  }

  @include breakpoint(medium) {
    margin: 18px 0;

    dl {
      padding: 0 10px 0 0;
      margin: 0 0 18px;
      line-height: 18px;
    }

    dt {
      font-weight: $global-weight-normal;
      margin: 0 0 5px;
      padding: 0;
    }

    dd.read-more a {
      display: inline;
      position: relative;
      padding-right: 0;
    }

    dt,
    dd {
      span {
        top: 1px;
        color: $dark-gray;
        font-size: rem-calc(12);
        margin-left: 5px;
      }
    }
  }

  &_1 {
    @include breakpoint(small only) {
      margin: 0;
      padding: 0 10px;

      dl {
        margin-bottom: 0;

        &:last-child {
          dt {
            border-bottom: none;
          }
        }
      }

      dt {
        padding: 15px 5px;
        box-sizing: border-box;
        border-bottom: 1px solid $medium-gray;
        font-weight: $global-weight-normal;
      }

      dd {
        display: none;
      }

      a {
        color: $black !important;
      }
    }

    @include breakpoint(medium) {
      dt {
        padding: 3px 50px 3px 5px;
        background: rgb(151 160 181 / 23%);

        span {
          color: $light-black;
          right: 4px;
          top: 4px;
          font-size: rem-calc(14);
        }
      }

      dd {
        padding: 1px 50px 1px 5px;
        margin-bottom: 2px;

        // &:nth-child(2n+1){
        //   background: rgba(165, 174, 195, 0.12);
        // }

        span {
          color: $light-black;
          right: 4px;
          top: 2px;
          font-size: rem-calc(12);
        }

        &.read-more {
          background: none;
          padding-right: 5px;

          .gl {
            position: relative;
            top: 0;
            right: 0;
          }
        }
      }
    }
  }
}

;@include ---ry84wxtmkt8.load-css("sass-embedded-legacy-load-done:3853");