@import 'assets/styles/core';

.charcounter {
  color: $small-font-color;
  display: flex;
  font-size: $small-font-size;
  justify-content: flex-end;
  margin-top: -$global-margin;
  padding: 0 $global-padding;
}
