@use "sass:meta" as ---uesajyerrrs;.react-dadata {
  &__container {
    position: relative;
  }

  &__input {
    @include form-element;

    appearance: none;
  }

  &__suggestions {
    background-color: $white;
    border-radius: $global-radius;
    box-shadow: 0 1px 6px 3px rgb(0 0 0 / 10%);
    left: 0;
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 0;
    text-align: left;
    top: 100%;
    z-index: 10;

    &-note {
      color: $dark-gray;
      font-size: rem-calc(14);
      padding: 10px 10px 5px;
    }
  }

  &__suggestion {
    background: none;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    font-size: rem-calc(15);
    padding: .5rem;
    text-align: left;
    width: 100%;

    &:hover {
      background-color: rgb(0 124 214 / 10%);
    }

    &--line-through {
      text-decoration: line-through;
    }

    &-subtitle {
      color: $dark-gray;
      font-size: rem-calc(15);
      margin-top: .5rem;

      &-item {
        display: inline-block;

        //margin-bottom: .5rem;
        margin-right: $global-margin;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &--current {
      background-color: rgb(0 124 214 / 15%);
    }
  }

  mark {
    background: none;
  }

  &--highlighted {
    color: map-get($foundation-palette, primary);
  }
}

;@include ---uesajyerrrs.load-css("sass-embedded-legacy-load-done:3873");