@use "sass:meta" as ---qyzyywc1vw;@use 'sass:math';

.action-panel {
  display: flex;
  margin: $global-margin 0;
  list-style: none;
  border-bottom: 1px solid $light-gray;
  font-size: rem-calc(13);
  color: $dark-gray;
  line-height: 1;
  padding-bottom: .5rem;

  &.market-product {
    border-bottom: none;
    justify-content: space-between;
    margin: 0;
  }

  @include breakpoint(small only) {
    border-bottom: none;
  }

  @include breakpoint(medium) {
    justify-content: space-between;
  }

  ul {
    list-style: none;
    margin: 0;
    display: block;
  }

  li {
    display: inline-block;
    margin: 0 math.div($global-margin, 2);
    vertical-align: top;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    a {
      @include color-mixin($dark-gray);

      &.change {
        @include color-mixin($success-color);
      }

      &.delete {
        @include color-mixin($alert-color);
      }

      &.analytics {
        @include color-mixin($primary-color);
      }
    }
  }
}

;@include ---qyzyywc1vw.load-css("sass-embedded-legacy-load-done:3814");