@use "sass:meta" as ---qyzyywc1vw;.redactor {
  &-box {
    border: $input-border;
    margin-bottom: 1rem;

    &-fullscreen {
      .redactor-toolbar-wrapper {
        background-color: #f3f3f3;
      }
    }
  }

  &-in {
    background-color: #fff;
    padding: $global-padding;
  }
}

;@include ---qyzyywc1vw.load-css("sass-embedded-legacy-load-done:3874");