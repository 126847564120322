@use "sass:meta" as ---ry84wxtmkt8;@use 'sass:math';

.company-tizer {
  @include grid-column-margin;

  display: flex;
  position: relative;
  border: 1px solid $medium-gray;
  border-radius: $global-radius;
  padding: $global-padding;
  gap: $global-margin;

  &.bottom-line {
    border-bottom: solid 1px $medium-gray;
    padding-bottom: $global-padding;
  }

  &.space-between {
    justify-content: space-between;
  }

  &__img {
    flex-shrink: 0;
    max-width: 5rem;
    width: 5rem;

    @include breakpoint(medium) {
      align-self: center;
      max-width: 8rem;
      width: 8rem;
    }

    @media (width <= 320px) {
      display: none;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: math.div($global-margin, 2);
  }

  &__title {
    display: flex;
    font-weight: $global-weight-bold;
    align-items: center;

    @include breakpoint(small only) {
      justify-content: space-between;
    }

    @include breakpoint(medium) {
      justify-content: flex-start;
      font-size: rem-calc(18);
    }

    i {
      margin: 0 0 0 rem-calc(10);
      flex-shrink: 0;
    }
  }

  &__desc {
    font-size: rem-calc(15);
    line-height: 1.3;
    display: flex;
    flex-direction: row;
    gap: $global-margin;

    @include breakpoint(small only) {
      font-size: rem-calc(12);
    }

    .subscribe {
      @include hide-for-only(small);

      align-self: center;
      flex-shrink: 0;
      max-width: 10rem;
      width: 10rem;
    }

    .more-link {
      color: $dark-gray;
    }
  }

  &__more {
    @include show-for(medium);

    color: $success-color;
    font-size: $small-font-size;

    a {
      display: inline-block;

      @include color-mixin($success-color);
    }
  }

  &__recomend {
    background-image: url('svg/recomend-status.svg');
    position: absolute;
    background-repeat: no-repeat;
    bottom: rem-calc(10);
    right: 1rem;
    width: rem-calc(80);
    height: 1.5rem;

    @include breakpoint(small only) {
      left: 1rem;
      bottom: .5rem;
      width: rem-calc(70);
      height: 1.25rem;
    }
  }

  &__contacts {
    color: $dark-gray;
    font-size: rem-calc(12);
    line-height: 1.4;
    margin-bottom: $global-margin;
  }
}

;@include ---ry84wxtmkt8.load-css("sass-embedded-legacy-load-done:3883");